.bg-light-gradient {
	background-image:url('/media/suomenlatu-fl/white_wall.png');
	background-size: cover;
	background-position: center;
}
.bg-height-105 {
	height: 105px;
}
.bg-light {
	background-color: #F5F5F5;
}
.bg-white {
	background-color: white;
}
.bg-fence::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-size: cover;
	background-position: center;
	opacity: 0.5;
	background-image: url('/media/suomenlatu-fl/footer-bg.jpg');
}
.bg-womenwithbirds {
	position: absolute;
	bottom: 0;
	left: -216px;
}
