.btn-primary-text {
	color: #FFFFFF;
	font-family: "brandon-grotesque", sans-serif;
	font-size: 18px;
	font-weight: bold;
	line-height: 26px;
}

.btn-white-text {
	color: #808080;
	font-family: "brandon-grotesque", sans-serif;
	font-size: 18px;
	font-weight: bold;
	line-height: 26px;
}

.fa-angle-double-down {
	color: #808080;
}

.btn-lg {
	height: 55px;
}

.blue-btn-img {
	padding-right: 12px;
	padding-left: 12px;
}

.bg-btn-blue-gradient {
	background: linear-gradient(180deg, #2C9BD9 0%, #0068B3 100%);
}

.bg-btn-white-gradient {
	background: linear-gradient(180deg, #FFFFFF 0%, #F4F4F4 100%);
	box-shadow: 0 3px 10px 0 rgba(0,0,0,0.05);
}
@media (max-width: 768px) {
	.btn-primary-text {
		font-size: 16px;
	}

	.btn-white-text {
		font-size: 16px;
	}

	.hidden-md-down {
		display: none !important;
	}
}
@media (max-width: 450px) {
	.btn-primary-text {
		font-size: 15px;
	}

	.btn-white-text {
		font-size: 15px;
	}

	.btn-lg {
		padding: 0.1rem 0.6rem;
	}

	.btn-lg {
		height: 45px;
		// width: 208px;
	}
	.side-button-text {
		font-size: 24px;
	}
	.side-button-text-order p {
		font-size: 24px;
	}
}
// SIDE BUTTONS

.side-button {
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.04), 0 0 26px 0 rgba(0,0,0,0.04);
}

.side-button-link:hover {
	text-decoration: none;
}

.side-button-image {
	height: 55px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 55px;
	-ms-flex: 0 0 55px;
	flex: 0 0 55px;
}

.side-button-order::after {
	content: '';
	background-color: #000000;
	opacity: 0.01;
	position: absolute;
	top: 0;
	left: 0;
	width: 55px;
	height: 55px;
	transition: background-color 0.25s ease;
}
.side-button-shop::after {
	content: '';
	background-color: #000000;
	opacity: 0.1;
	top: 0;
	left: 0;
	position: absolute;
	width: 55px;
	height: 55px;
	transition: background-color 0.25s ease;
}
.side-button-services::after {
	content: '';
	background-color: #000000;
	opacity: 0.05;
	top: 0;
	left: 0;
	position: absolute;
	width: 55px;
	height: 55px;
	transition: background-color 0.25s ease;
}
.side-button-text-order p {
	color: #808080;
	font-family: "brandon-grotesque", sans-serif;
	font-size: 28px;
}

.side-button-text {
	color: #FFFFFF;
	font-family: "brandon-grotesque", sans-serif;
	font-size: 28px;
}
@media (max-width: 450px) {
	.side-button-text {
		font-size: 24px;
	}
	.side-button-text-order p {
		font-size: 24px;
	}
}
