.modal-container {
	opacity: 0;
	height: 100vh;
	width: 100%;
	background-color: rgba(0,0,0,0.7);
	position: fixed;
	top: 0;
	z-index: 9999;
	-webkit-transition: all 0.15s linear;
	transition: all 0.15s linear;
	pointer-events: none;
}
.form-thanks-for-ordering {
	-webkit-transition: all 0.15s linear;
	transition: all 0.15s linear;
	opacity: 0;
	position: fixed;
	z-index: 9999;
	top: 40%;
	background-color: white;
	transform: translateX(-50%);
	left: 50%;
	padding: 50px;
	width: 600px;
	-webkit-box-shadow: 1px 1px 16px 0px rgba(0,0,0,0.58);
	-moz-box-shadow: 1px 1px 16px 0px rgba(0,0,0,0.58);
	box-shadow: 1px 1px 16px 0px rgba(0,0,0,0.58);
	font-size: 20px;
	font-family: 'Lato', sans-serif;
}

.custom-newsletterform {
	-webkit-transition: all 0.15s linear;
	transition: all 0.15s linear;
	opacity: 0;
	position: fixed;
	z-index: 9999;
	top: 40%;
	background-color: white;
	transform: translateX(-50%);
	left: 50%;
	padding: 50px;
	width: 600px;
	-webkit-box-shadow: 1px 1px 16px 0px rgba(0,0,0,0.58);
	-moz-box-shadow: 1px 1px 16px 0px rgba(0,0,0,0.58);
	box-shadow: 1px 1px 16px 0px rgba(0,0,0,0.58);
}
@media (max-width: 768px) {
	.custom-newsletterform, .form-thanks-for-ordering {
		width: 500px;
	}
}
@media (max-width: 568px) {
	.custom-newsletterform, .form-thanks-for-ordering {
		width: 94%;
		padding:30px;
	}
}
.show-with-effect {
	opacity: 1;
	pointer-events: auto;
}
.formsubmit-button {
	font-size: 16px;
	font-family: 'Lato', sans-serif;
}
.order-newsletter-header {
	font-size: 24px;
	font-weight: bold;
	font-family: 'brandon-grotesque', sans-serif;
}
.label-text-order {
	pointer-events: none;
	top: 19px;
	left: 0;
	margin: 0;
	position: absolute;
	font-family: 'Lato', sans-serif;
	-webkit-transform: translate(14px, 10px);
	-ms-transform: translate(14px, 10px);
	transform: translate(14px, 10px);
	transition: transform 250ms ease, color 250ms ease, -webkit-transform 250ms ease;
	font-size: 0.875rem;
	color: #878787;
	font-size: 12px;
	font-weight: bold;
}
.custom-input:active ~ .label-text-order {
	-webkit-transform: translate(0, -25px);
	-ms-transform: translate(0, -25px);
	transform: translate(0, -25px);
}
.custom-input:focus ~ .label-text-order {
	-webkit-transform: translate(0, -25px);
	-ms-transform: translate(0, -25px);
	transform: translate(0, -25px);
}

.custom-input.has-value ~ .label-text-order {
	-webkit-transform: translate(0, -25px);
	-ms-transform: translate(0, -25px);
	transform: translate(0, -25px);
}
.close-order {
	position: absolute;
	top: 8px;
	right: 18px;
	font-weight: bold;
	font-family: 'brandon-grotesque', sans-serif;
	font-size: 24px;
	cursor: pointer;
	&:hover {
		color: rgba(0, 0, 0, 0.7);
	}
}
