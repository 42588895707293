.news-list-wrapper {}

.news-list-head {}

.news-icon {
	height: 21px;
	width: 20px;
}

.news-list-header {
	color: #202020;
	font-family: "brandon-grotesque", sans-serif;
	font-size: 38px;
}

.news-list-item {
	padding-bottom: 29px;
}

.news-list-text {
	padding-right: 40px;
	padding-left: 20px;
}
.news-list-image {
	flex: 0 0 200px;
}
.news-list-image img {
	max-width: none !important;
	width: 200px;
	height: 134px;
}

.news-single-header {
	font-family: Lato;
	font-size: 16px;
	font-weight: 900;
	line-height: 22px;
}

.news-single-header a {
	color: #202020;
}

.news-single-text {
	color: #202020;
	font-family: Lato;
	font-size: 14px;
	margin-bottom: 5px;
	line-height: 20px;
}

.foot a {
	color: #2370B7;
	font-family: "brandon-grotesque", sans-serif;
	font-size: 20px;
	font-weight: bold;
}

.news-single-categories {
	opacity: 0.9;
	color: #808080;
	font-family: Lato;
	font-size: 13px;
	font-weight: bold;
}
@media (max-width: 568px) {
	.news-list-image img {
		height: 73px;
		width: 110px;
	}
	.news-list-image {
		flex: 0 0 110px;
	}
	.foot a {
		font-size: 16px;
	}

	.news-list-header {
		font-size: 28px;
	}
}
@media (max-width: 768px) {
	.news-list-text {
		padding-right: 0;
	}
}

h2.lowerpage-newslist-header {
	color: #202020;
	font-family: "brandon-grotesque", sans-serif;
	font-size: 28px;
}

.lowerpage-newslist-header-link {
	color: #2370B7;
	font-family: "Brandon-Grotesque", sans-serif;
	font-size: 20px;
	font-weight: bold;
}
.news-list-lower-image img {
	max-width: none !important;
	width: 200px;
	height: 134px;
}
@media (max-width: 992px) {
	.news-list-lower-image {
		flex: 0 0 200px;
	}
}
@media (max-width: 568px) {
	.news-list-lower-image img {
		height: 73px;
		width: 110px;
	}
	.news-list-lower-image {
		flex: 0 0 110px;
	}
}
