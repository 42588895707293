
#hero .swiper-pagination {
	bottom: 20px;
	right: 0;
	position: absolute;
	z-index: 4;
}

#hero .swiper-pagination span {
	background: #eeeeee;
	border-radius: 50%;
	-moz-border-radius: 50%;
	cursor: pointer;
	display: inline-block;
	margin: 0 5px;
	height: 16px;
	width: 16px;
}

#hero .swiper-pagination span.swiper-active-switch { background: #0567b1; }
@media (max-width: 768px) {
	#hero .swiper-slides {
		// margin: -60px 0 0 0;
		height: auto;
		position: relative;
	}
}
@media (max-width: 1200px) {
	#hero .swiper-pagination {
		right: 20px;
	}
}

// HERO
body.default #hero { z-index: 1; }

#hero .hero-wrapper {
	width: 100%;
	// height: 510px;
	margin: 0 0 0 0;
	position: relative;
	-webkit-transition: height 400ms linear;
	-moz-transition: height 400ms linear;
	-o-transition: height 400ms linear;
	transition: height 400ms linear;
}

body.home #hero .hero-wrapper {  }

body.theme-hero #hero .hero-wrapper{
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	margin-bottom: 0;
	height: calc(100vh - 145px);
}

body.events #hero .hero-wrapper {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	margin-bottom: 0;
	height: 410px;
}

body.default-hero #hero .hero-wrapper {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	margin-bottom: 0;
	height: calc(100vh - 145px);
}

body.no-hero #hero .hero-wrapper {
	background: url(/media/layout/img/paper-bg.jpg);
	background-repeat: repeat;
	margin-bottom: 0;
	height: calc(100vh - 145px);
}

#hero .swiper-container,
#hero .swiper-wrapper { height: 100% !important; }

#hero .swiper-container { z-index: 1; }

body.facelift #hero .swiper-slide {
	height: calc(100vh - 250px) !important;
	z-index: 1;
}
@media (max-width: 768px) {
	body.facelift #hero .swiper-slide {
		height: calc(100vh - 209px) !important;
		z-index: 1;
	}
}
#hero .swiper-slide .slide-bg {
	background-size: cover;
	background-position: center;
	height: 100%;
	width: 100%;
}

#hero .swiper-slide .slide-bg.top { background-position: top center; }

#hero .swiper-slide .slide-bg.middle { background-position: middle center; }

#hero .swiper-slide .slide-bg.bottom { background-position: bottom center; }

#hero .swiper-slide .box { display: none; }
@media (max-width: 450px) {
	#hero .swiper-slides::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		-webkit-transition: background-color 0.25s ease;
		transition: background-color 0.25s ease;
	}
}
#hero .swiper-slides {
	height: 100%;
	width: 100%;
	top: 0;
	position: absolute;
	z-index: 3;
}

#hero .swiper-slides .container .holder { position: relative; }

#hero .single-slide {
	width: 100%;
	bottom: 0;
	left: 0;
	position: absolute;
	z-index: 3;
}

#hero .single-slide .container { position: relative; }

body.facelift #hero .box {
	// background: rgba(5, 103, 177, 0.8);
	color: $white;
	display: none;
	border-left: 10px solid #e8442f;
	// padding: 30px 30px 60px 30px;
	// bottom: -440px;
	width: 60%;
	// position: absolute;
	// -moz-box-sizing: border-box;
	// box-sizing: border-box;
}
body.facelift #hero .box a {
	font-family: 'brandon_printeddouble', "brandon-grotesque", sans-serif;
	text-transform: uppercase;
	color: $white;
	line-height: 72px;
	font-size: 62px;
}
@media (max-width: 940px) {
	body.facelift #hero .box {
		width: 70%;
	}
	body.facelift #hero .box a {
		font-size: 48px;
		line-height: 45px;
	}
}
@media (max-width: 768px) {
	body.facelift #hero .box a {
		font-size: 36px;
	}
}
@media (max-width: 450px) {
	body.facelift #hero .box a {
		font-size: 28px;
		line-height: 35px;
	}
	body.facelift #hero .box {
		border-left: 4px solid #e8442f;
		width: 100%;
	}
}
@media (max-width: 371px) {
	body.facelift #hero .box a {
		font-size: 20px;
		line-height: 25px;
	}
}
body.facelift #hero .box a:hover {
	text-decoration: none;
}

html.ie8 #hero .box,
html.ie9 #hero .box {
	background-color: none;
	background: url(/media/layout/img/bg-blue.png) top left repeat;
	border-width: 0;
}

#hero .single-slide .box {
	display: block;
	padding-bottom: 30px;
	bottom: 180px;
}

#hero .box.left { left: 0; }

#hero .box.right { right: 0; }

#hero .box h1 {
	color: #ffffff;
	margin: 0 0 10px 0;
}

#hero .box p {
	margin: 0;
	/*font-size: 80%;*/
}

#hero .box .nappi {
	background: none;
	border-color: rgba(255, 255, 255, 0.5);
	border-width: 1px 1px 0 1px;
	border-radius: 0;
	-moz-border-radius: 0;
	bottom: 0;
	left: 30px;
	position: absolute;
}

#hero .box .nappi:hover { background: #045088; }

#hero .box .separator { margin: 0 10px; }

// #hero .hero-wave {
// 	background-image: url(/media/layout/img/wave-hero.png);
// 	background-position: center;
// 	background-size: 100% 40px;
// 	width: 100%;
// 	height: 40px;
// 	bottom: 0;
// 	left: 0;
// 	position: absolute;
// 	z-index: 2;
// }

#hero .hero-wave img { display: none; }


#hero .hero-msg {
	margin-top: -27px;
	width: 100%;
	top: 50%;
	left: 0;
	position: absolute;
	text-align: center;
}
// @media (max-width: 768px) {
// 	#hero .swiper-container, #hero .swiper-wrapper { height: 400px; }
// }
@media (max-width: 450px) {
	body.facelift #hero .swiper-slide {
		height: 190px !important;
	}
	// #hero .swiper-container, #hero .swiper-wrapper { height: 300px; }
}
.swiper-slide div .small {
font-size: 16px;
}
