.section__header img {
	height: 19px;
	width: 19px;
	margin-bottom: 4px;
}

.section__header {
	color: #202020;
	font-family: Brandon-Grotesque, sans-serif;
	font-size: 24px;
	font-weight: bold;
	text-transform: uppercase;
}

.news-latujapolku-item {
	padding-left: 24px;
	padding-bottom: 36px;
}

.news-latujapolku-header {
	background-color: #505050;
	height: 77px;
}
.news-latujapolku-single-image {
	flex: 0 0 144px;
}
.news-latujapolku-single-image img {
	max-width: none;
	height: 81px;
	width: 144px;
}

.news-latujapolku-single-text {
	padding-left: 17px;
}
@media (max-width: 768px) {
	.news-latujapolku-single-text {
		padding-left: 0;
	}

	.news-latujapolku-header {
		padding-left: 22px;
	}
}

.news-latujapolku-text-header {
	color: #202020;
	font-family: Lato;
	font-size: 15px;
	font-weight: 900;
}

.news-latujapolku-text-date {
	color: #808080;
	font-family: Lato;
	font-size: 13px;
	font-weight: bold;
}

.news-latujapolku-text-link {
	color: #4CB0E8;
	font-family: Lato;
	font-size: 13px;
	font-weight: bold;
}

.feed-single-item-header {
	color: #202020;
	font-family: Lato;
	font-size: 14px;
	line-height: 21px;
	font-weight: bold;
}

.news-latujapolku-footer {
	padding-left: 24px;
	height: 80px;
	background-color: #505050;
	color: white;
	font-family: Lato;
	font-size: 14px;
	font-weight: bold;
	line-height: 22px;
}

.news-latujapolku-footer a {
	color: #FFFFFF;
	font-family: Lato;
	font-size: 14px;
	font-weight: bold;
	line-height: 22px;
}

.feed-single-item {
	padding-left: 24px;
	padding-top: 15px;
	padding-right: 24px;
}

.feed-single-item-image {
	padding-top: 3px;
}

.feed-single-item-image img {
	width: 20px;
	height: 20px;
}

.feed-single-item-text {
	padding-left: 24px;
}

.feed-single-item-text-date {
	color: #808080;
	font-family: Lato;
	font-size: 13px;
	font-weight: bold;
	padding-top: 9px;
}
@media (max-width: 568px) {
	.section__header {
		font-size: 18px;
	}
}

.ig-single-item {
	padding: 25px;
}

.ig-single-image {
	width: 100%;
	padding-top: 62.5%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.ig-feed-header {
	color: #202020;
	font-family: "Brandon-Grotesque", sans-serif;
	font-size: 24px;
	font-weight: bold;
	line-height: 34px;
	text-transform: uppercase;
}
.ig-feed-header img {
	height: 21px;
}
.ig-single-image img {
	max-width: none;
	width: 100%;
	height: auto;
}

.ig-single-text {
	padding-top: 12px;
	color: #202020;
	font-family: Lato;
	font-size: 14px;
	line-height: 21px;
	word-break: break-all;
}
