.mm-menu-fix {
	float: left;
	width: 100%;
	position: relative;
}

.printed-double-header {
	color: #2370B7;
	font-family: 'brandon_printeddouble', sans-serif;
	font-size: 32px;
}

.printed-double-header-number {
	// padding-left: 18px;
	// padding-right: 17px;
	// padding-top: 2px;
	// padding-bottom: 3px;
	background-color: #2370B7;
	min-width: 52px;
	min-height: 52px;
	// background-image: url('/media/suomenlatu-fl/bg-blue.png');
	border-radius: 100%;
	font-family: "brandon-grotesque", sans-serif;
	color: white;
	font-weight: 900;
	font-size: 32px;
}

.border-bottom-light {
	border-bottom: 1px solid #DEDEDE;
}

.pos-relative {
	position: relative;
}

.pos-absolute {
	position: absolute;
}

.else-text {
	color: #808080;
	font-family: "brandon-grotesque", sans-serif;
	font-size: 18px;
	line-height: 26px;
}

.mw-208 {
	max-width: 208px;
}

.pt-103 {
	padding-top: 103px;
}

.pb-162 {
	padding-bottom: 162px;
}

.pr-20px {
	padding-right: 20px;
}

.pl-40px {
	padding-left: 40px;
}

.else-wrapper {
	margin-left: 27px;
	margin-right: 29px;
}
@media (max-width: 450px) {
	.else-wrapper {
		margin-left: 17px;
		margin-right: 18px;
	}

	.mw-208 {
		max-width: 100%;
	}
}
@media (max-width: 375px) {
	.else-wrapper {
		margin-left: 10px;
		margin-right: 12px;
	}
}
@media (max-width: 568px) {
	.hidden-sm-down {
		display: none !important;
	}
}
@media (min-width: 568px) {
	.hidden-sm-up {
		display: none !important;
	}
}
@media (max-width: 768px) {
	.hidden-md-down {
		display: none !important;
	}

	.pt-103 {
		padding-top: 10px;
	}

	.pb-162 {
		padding-bottom: 10px;
	}

	.else-text {
		font-size: 14px;
	}

	.printed-double-header {
		font-size: 22px;
	}

	.printed-double-header-number {
		font-size: 28px;
		min-height: 45px;
		min-width: 45px;
	}
}
@media (max-width: 992px) {
	.hidden-lg-down {
		display: none !important;
	}
}

.body-container {
	background-color: #F5F5F5;
	// clear: both;
	// width: 100%;
	// position: relative;
}

.text-lowerpage-newslist p.title a {
	font-weight: 900;
	font-family: Lato;
	font-size: 16px;
	color: #202020;
}

.text-lowerpage-newslist p.intro {
	font-size: 14px !important;
	font-family: Lato;
	color: #202020;
}

.text-lowerpage-newslist p.meta {
	opacity: 0.9;
	color: #808080 !important;
	font-family: Lato;
	font-size: 13px !important;
}
.noResultsText {
	font-family: 'brandon-grotesque', sans-serif;
	font-size: 24px;
}
