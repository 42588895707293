// TOPBAR
.btn-submit {
	position: absolute;
	height: 20px;
	width: 20px;
	right: 6px;
	top: 8px;
	background-image: url('/media/suomenlatu-fl/search-icon-blue.png');
	background-color: transparent;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	&:active {
		background-image: url('/media/suomenlatu-fl/search-icon-blue.png');
	}
}
input.searchbar-input {
	padding-top: 11px;
	z-index: 99999;
	border-top: none;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	@media (max-width: 940px) {
		border-bottom-right-radius: 0px;
	}
}
.top-searchbar {
	display: block;
	top: 0;
	right: 0;
	position: absolute;
}
@media (max-width: 940px) {
	.top-searchbar {
		width: 49.5%;
		display: inline-block;
	}
}
@media (max-width: 450px) {
	.top-searchbar {
		width: 50%;
	}
}
.top-searchbar-form {
	display: none;
	position: absolute;
	right: 0;
	top: 35px;
	width: 200px;
	z-index: 9999;
}
.btn-transparent {
	position: absolute;
	right: 6px;
	top: 8px;
	background-image: url('/media/suomenlatu-fl/icon-search.png');
	background-color: transparent;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	&:active {
		background-image: url('/media/suomenlatu-fl/icon-search.png');
	}
}
#header .topbar {
	position: relative;
}
#header .topbar .menu a:hover {

}
.topbar .menu li.level1::after {
	content: '::';
	color: white;
	position: absolute;
	top: 3px;
	right: 0px;
}
.topbar .menu li.level1.last::after {
	display: none;
}
#header .topbar .menu li {
	display: table-cell;
	vertical-align: top;
	text-align: left;
	position: relative;
	padding-left: 10px;
}
.languageText {
	color: $blue !important;
}
#header .topbar .menu a {
	font-family: "Brandon Grotesque", sans-serif;
	display: block;
	color: $white;
	padding: 0 2px;
	height: 35px;
	font-size: 12px;
	font-weight: bold;
	line-height: 35px;
}
#header .translations .current-lang {
	display: block;
	padding: 0 32px 0 10px;
	height: 35px;
	position: relative;
	line-height: 35px;
	white-space: nowrap;
	color: $white;
}
.w-200px {
	width: 200px !important;
}
#header .translations .current-lang:hover {
	cursor: pointer;
}
#header .translations .current-lang .icon {
	background-position: 2px -94px;
	height: 35px;
	width: 35px;
	top: 0;
	position: absolute;
}
#topbar-search .search-icon,
#topbar-search .formCustomSubmit {
	cursor: pointer;
	display: block;
	padding: 0;
	height: 35px;
	width: 35px;
	top: 0;
	right: 0;
	position: absolute;
	text-indent: 5000em;
	overflow: hidden;
	z-index: 2;
	background-position: -30px 1px;
}
.bg-position {
	transition: all 0.2s ease-in-out 0.2s;
	background-position: 0px 0px !important;
}
#topbar-search .formTextinput {
	background: transparent;
	border-radius: 0;
	-moz-border-radius: 0;
	border-width: 0 0 0 0px;
	padding: 10px 35px 10px 0px;
	top: 0;
	right: 0;
	position: absolute;
	font-size: 85%;
	line-height: 100%;
	z-index: 1;
	transition: all 0.3s ease-in-out 0s;
	box-shadow: 0 0 0;
}

.icon,
#fixednav .search-icon,
#fixednav .formCustomSubmit,
#topbar-search .search-icon,
#topbar-search .formCustomSubmit,
#footer .search-footer .formCustomSubmit {
	background-image: url(/media/layout/img/sprite-icons2x.png);
	background-size: 256px 128px;
}
#topbar-search.open .formTextinput,
#topbar-search.open .search-icon,
#topbar-search.open .formCustomSubmit{ background-color: $white; }

@media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-devicepixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {

	.icon,
	#fixednav .search-icon,
	#fixednav .formCustomSubmit,
	#topbar-search .search-icon,
	#topbar-search .formCustomSubmit,
	#footer .search-footer .formCustomSubmit {
		background-image: url(/media/layout/img/sprite-icons2x.png) !important;
		background-size: 256px 128px;
	}

	.icon.anchor-arrow {
		background-image: url(/media/layout/img/icon-anchor-arrow2x.png) !important;
		background-size: 40px 36px;
	}

}
@media (max-width: 940px) {
	#topbar-search .formTextinput {
		padding-left: 0px;
		width: 0px;
	}
	#header .topbar .menu li.translations,
	#header .topbar .menu li#topbar-search { width: 49.5%; display: inline-block; }
	#header .topbar .menu, #header .topbar .menu li { display: inline-block; }
}
@media (max-width: 450px) {
	#header .topbar .menu li.translations,
	#header .topbar .menu li#topbar-search { width: 49.5%; display: inline-block; }
	#topbar-search .formTextinput { width: 0% }
	#header .topbar .menu, #header .topbar .menu li { display: inline-block; }
}
// #header .topbar .menu li:after {
// 	content: ': :';
// 	color: white;
// 	display: inline-block;
// }
#header .topbar .menu .kiilopaa a { color: $white; }
#header .topbar .menu .liikuntapalvelut a { color: $white; }
#header .topbar .menu .hiihtopalvelut a { color: $white; }

// #header .topbar .menu .latupolku a {
//   background-image: url(/media/layout/img/logo-latujapolku.png);
//   background-position: center;
//   background-size: 94px 15px;
//   background-repeat: no-repeat;
//   min-width: 94px;
//   text-indent: -500em;
//   overflow: hidden;
// }
// MAIN NAVBAR
.navigation-bg {
	background-image: url('/media/suomenlatu-fl/bg-header-vaneri.png');
}
.topbar-bg {
	background-image: url('/media/suomenlatu-fl/bg-header-blue.png');
}
#header .navigation-bg .container .navigation .logo {
	background-image: url('/media/suomenlatu-fl/sl-logo.png');
	background-position: top left;
	background-size: 100%;
	display: block;
	width: 205px;
	height: 65px;
	text-indent: -500em;
	overflow: hidden;
}
#header .navigation-bg .container .navigation .logo {
	background-image: url('/media/suomenlatu-fl/sl-logo.png');
	background-position: top left;
	background-size: 100%;
	display: block;
	width: 205px;
	height: 65px;
	text-indent: -500em;
	overflow: hidden;
}
#header .navigation {
	padding-top: 21px;
	padding-bottom: 24px;
	position: relative;
}
#header .navigation .menu li.contact:before {
	content: '';
	display: inline-block;
	height: 25px;
	margin-right: 20px;
	margin-left: -10px;
	margin-top: -10px;
	border-color: $blue;
	border-style: solid;
	border-width: 0 0 0 2px;
	vertical-align: middle;
}
body.palvelut #header .navigation .menu li.contact:before {
	display: none;
}

// #header .navigation .menu a {
// 	text-transform: uppercase;
// 	display: inline-block;
// }
.vaikuta a.level1, .osallistu a.level1, .ulkoile a.level1, .liiku a.level1  {
	text-transform: uppercase;
}

#header .navigation .mmenu-btn .line {
	display: block;
	height: 3px;
	width: 100%;
	background: $blue;
	margin-top: -2px;
	top: 50%;
	left: 0;
	position: absolute;
}
@media (max-width: 940px) {
	#header .navigation {
		padding-left: 20px;
		padding-right:20px;
		padding-top: 21px;
		padding-bottom: 24px;
		position: relative;
	}
}

@media (max-width: 768px) {
	#header .navigation {
		padding-top: 10px;
		padding-bottom: 16px;
	}
	#header .navigation .mmenu-btn {
		bottom: 23px;
	}

	#header .navigation-bg .container .navigation .logo {
		display: inline-block;
		width: 116px;
		height: 36px;
	}
}
// FIXED NAVBAR

#fixednav {
	background: url('/media/suomenlatu-fl/bg-header-vaneri.png');
	display: block;
	height: 65px;
	top: -68px;
	left: 0;
	position: fixed;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.04);
	z-index: 11;
}

.ie8 #fixednav { border-width: 1px; }

body.events #fixednav { display: none; }

#fixednav .container { position: relative; }

#fixednav .logo {
	background-image: url(/media/layout/img/logo.png);
	background-position: center;
	background-size: 100%;
	display: inline-block;
	margin: 15px 10px;
	width: 116px;
	height: 35px;
	text-indent: -500em;
	overflow: hidden;
}

body.palvelut.liikuntapalvelut #fixednav .logo {
	background-image: url(/media/layout/img/logo-liikuntapalvelut-second.png);
	width: 146px;
	height: 35px;
}

body.palvelut.hiihtopalvelut #fixednav .logo {
	background-image: url(/media/suomenlatu-fl/SL-paloheina-logo.png);
	width: 131px;
	height: 40px;
	margin: 10px 10px;
}

/*
#fixednav .logo img {
display: block;
height: 35px;
}
*/

#fixednav .menu {
	position: absolute;
	top: 0;
	right: 105px;
}

body.palvelut #fixednav .menu { right: 55px; }

body.events #fixednav .menu,
body.events #fixednav-search { display: none; }

#fixednav .menu li {
	display: inline-block;
	position: relative;
}

#fixednav .menu a {
	color: #4c4c4c;
	display: inline-block;
	padding: 0 10px;
	height: 65px;
	line-height: 65px;
	font-family: 'brandon-grotesque', sans-serif;
	font-size: 95%;
	font-weight: bold;
}

#fixednav .menu a:hover,
#fixednav .menu a.current { color: #0567b1; }

#fixednav .menu li.vaikuta { margin-right: 7px; }

#fixednav .menu li.contact a { font-family: 'brandon-grotesque', sans-serif; }

#fixednav .menu li.contact:before {
	content: '';
	display: inline-block;
	height: 30px;
	margin-right: 7px;
	margin-top: -5px;
	border-color: #e6e6e6;
	border-style: solid;
	border-width: 0 0 0 1px;
	vertical-align: middle;
}

body.palvelut #fixednav .menu li.contact:before { display: none; }

#fixednav .search {
	top: 15px;
	right: 60px;
	position: absolute;
}

#fixednav .search-icon,
#fixednav .formCustomSubmit {
	background-image: url(/media/layout/img/sprite-icons.png) 0 0 no-repeat;
	cursor: pointer;
	display: block;
	padding: 0;
	height: 32px;
	width: 32px;
	top: 0;
	background-color: transparent;
	right: 1px;
	position: absolute;
	text-indent: 5000em;
	overflow: hidden;
	z-index: 2;
}

#fixednav .formCustomSubmit {
	display: none;
}

#fixednav .formTextinput {
	background: #ffffff;
	border-radius: 32px;
	-moz-border-radius: 32px;
	border-width: 1px;
	height: 32px;
	padding: 7px 32px 7px 0px;
	top: 0;
	right: 0;
	position: absolute;
	font-size: 85%;
	line-height: 100%;
	z-index: 1;
	transition: all 0.3s ease-in-out 0s;
	box-shadow: 0 0 0;
}

#fixednav .back2top {
	background: #cccccc;
	border-radius: 50%;
	-moz-border-radius: 50%;
	top: 15px;
	right: 10px;
	position: absolute;
}

#fixednav .back2top:hover { background: #0567b1; }
@media (max-width: 940px) {
	#fixednav { display: none; }
}
