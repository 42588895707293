.site-footer {
	background-color: #F5F5F5;
}

.footer-editor p {
	color: #202020;
	font-family: "Brandon-Grotesque", sans-serif;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 0;
}

.footer-section-1 {
	margin-top: 71px;
	padding-bottom: 67px;
}
@media (max-width: 768px) {
	.footer-section-1-img {
		text-align: center;

		& img {
			height: 40px;
			width: 127px;
		}
	}
	.footer-section-1 {
		padding-bottom: 14px;
	}
}
.border-bottom {
	border-bottom: 1px solid #D8D8D8;
}
.footer-search-input {
	position: relative;
	background-color: #2370B7;
	color: white;
	border: 0;
	border-radius: 0;
}

.footer-search-input::-webkit-input-placeholder {
	color: white;
	font-family: Lato;
	font-size: 13px;
	font-weight: bold;
}

.footer-search {
	width: 100%;
	position: relative;
}

.btn-blue {
	background-color: #2370B7;
	color: white;
	text-align: center;
	border-radius: 0;
	border: none;
	font-family: Lato;
	font-size: 13px;
	font-weight: bold;
}

.footer-menu {
	padding-top: 59px;
	padding-bottom: 91px;
}

.footer-menu-links {
	list-style: none;

	& > .level1 {
		text-transform: uppercase;
		color: #2370B7;
		font-family: "Brandon-Grotesque", sans-serif;
		font-size: 20px;
		font-weight: 900;
		padding-bottom: 16px;
		line-height: 20px;
	}
	& > .level2 > a {
		color: #202020;
		font-family: Lato;
		font-size: 14px;
	}
}
.border-lr {
	border-left: 1px solid #D8D8D8;
	border-right: 1px solid #D8D8D8;
}

@media (max-width: 992px) {
	.border-lr {
		border-left: none;
	}
}
.footer-second-menu {
	list-style: none;
	line-height: 18px;
	text-transform: uppercase;
	color: #2370B7;
	font-family: "Brandon-Grotesque", sans-serif;
	font-size: 12px;
	font-weight: bold;
}
#copyright {
	background: #333333;
	color: #ffffff;
	padding: 30px 0;
	font-size: 85%;
	text-transform: uppercase;
	text-align: center;
}

#copyright .addthis_sharing_toolbox { margin-right: 46px; }
@media (max-width: 568px) {
	#copyright .addthis_sharing_toolbox { margin-right: 10px; }
	.at-icon-weapper {
		height: 22px;
		width: 22px;
	}
}
@media (max-width: 768px) {
	.footer-menu {
		padding-top: 20px;
		padding-bottom: 31px;
	}
}
