.event-list-header {
	color: #202020;
	font-family: Brandon-Grotesque, sans-serif;
	font-size: 38px;
	margin-bottom: 12px;
}

.event-image {}

.event-image img {
	max-width: none;
	width: 100%;
	height: auto;
}

.event-single-item {
	background-color: $white;
}

.event-text-area {
	padding: 17px 17px 18px 20px;
}

.event-text {
	font-family: Lato;
	font-size: 16px;
	font-weight: 900;
	text-transform: uppercase;

	& a {
		color: #EB4721;
	}
}

.event-times-area {
	height: 30px;
	width: 75px;
}

.event-times {
	opacity: 0.9;
	color: #202020;
	font-family: Lato;
	font-size: 13px;
	font-weight: bold;
	line-height: 15px;
}

.event-property-area {}

.event-property-area p {
	min-height: 26px;
	border-radius: 3px;
	background-color: #F7F7F7;
	color: #202020;
	font-family: Lato;
	font-size: 13px;
	padding-top: 3px;
}

.border-right-light {
	border-right: 1px solid #EAEAEA;
}

.events-search_input {
	position: relative;
	color: #505050;
	width: 100%;
}

.events-search_input i {
	z-index: 999;
	top: 14px;
	left: 11px;
	font-size: 14px;
}

.events-search_input input {
	padding-left: 36px;
	height: 46px;
	border: 1px solid #DEDEDE;
	border-radius: 3px;
	width: 100%;
}

.events-search_button {
	width: 115px;
}

.nice-select, .js-select2, .select2 {
	width: 100%;
	margin-bottom: 8px;
	height: 46px;
	border-radius: 3px;
	border: none;
	padding-top: 2px;
}

.nice-select > ul.list {
	max-height: 400px;
	max-width: 100%;
	overflow-y: auto;
}

.date-wrapper > input {
	height: 46px;
	box-shadow: none;
	border: none;
	border-radius: 3px;
}
.date-wrapper > .picker__input {
	height: 40px;
	font-size: 90% !important;
	box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.1);
}
body.facelift .fa-chevron-down {
	color: #545454;
	font-size: 12px;
}

.show-more-text {
	color: #EB4721;
	font-family: Brandon-Grotesque, sans-serif;
	font-size: 20px;
	font-weight: bold;
	padding-left: 9px;
}
@media (max-width: 568px) {
	.event-list-header {
		font-size: 28px;
	}
}
@media (max-width: 768px) {
	.show-more-text {
		font-size: 16px;
	}
	.events-search_button {
		width: 73px;
	}
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
	width: 100%;
	height: 46px;
	border-radius: 3px;
	border: none;
	padding-top: 8px;
	padding-left: 15px;
	font-family: 'Lato', Arial, sans-serif;
	font-size: 85%;
	color: #4c4c4c;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 26px;
	position: absolute;
	top: 12px;
	right: 8px;
	width: 20px;
}
.select2-container--default .select2-selection--single {
	width: 100%;
	height: 46px;
	border-radius: 3px;
	border: none;
}
.select2-dropdown {
	border: none;
}
