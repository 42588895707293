.box-header {
	color: #202020;
	font-family: "Brandon-Grotesque", sans-serif;
	font-size: 28px;
	font-weight: bold;
	padding-bottom: 15px;
}

.box-text {
	color: #202020;
	font-family: Lato;
	font-size: 16px;
	line-height: 24px;
}

.box-shadow {
	box-shadow: 0 -2px 82px 0 rgba(0,0,0,0.1);
}

.box-lift {
	padding: 31px 25px 38px 35px;
}

.box-lift-header {
	color: #202020;
	font-family: "Brandon-Grotesque", sans-serif;
	font-size: 28px;
	font-weight: bold;
	padding-bottom: 24px;
}

.box-lift-text {
	color: #202020;
	font-family: Lato;
	font-size: 16px;
	line-height: 24px;
}

@media (max-width: 768px) {
	.box-header {
		font-size: 22px;
	}
	.box-text {
		font-size: 14px;
	}
	.box-lift-header {
		font-size: 22px;
	}
	.box-lift-text {
		font-size: 14px;
	}
}
