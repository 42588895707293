#hero .hero-wave {
	display: none;
}
body.theme-hero.default #body {
	margin: -45vh 0 0 0;
	z-index: 4;
	padding-bottom: 20px;
}
@media (max-width: 768px) {
	body.theme-hero.default #body {
		margin: -150px 0 0 0;
		padding-bottom: 20px;
	}
}
@media (max-width: 450px) {
	body.theme-hero.default #body {
		margin: -50px 0 0 0;
		padding-bottom: 20px;
	}
}
