.ui-widget {
    font-family: 'Lato', Arial, sans-serif;
	box-shadow: 0 3px 6px rgba(0,0,0,0.13);
}

.ui-widget-header {
	background: #ffffff !important;
	border: none;
}

.ui-widget-content {
    border: 1px solid #eeeeee;
}

.ui-datepicker .ui-datepicker-title select {
    height: 30px;
	font-size: 14px;
	padding: 4px 15px;
}

.ui-datepicker select.ui-datepicker-month {
	margin-right: 5px;
}

.ui-corner-all {
		border-radius: 0;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
    border: solid 1px #eeeeee;
    background: #f4f4f4;
    text-align: center;
}

.ui-state-active, .ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
    border: solid 1px #eeeeee;
    background: #4cb0e8;
	border-radius: 3px;
	color: #fff;
}
